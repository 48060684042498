<template>
  <div>
    <infoHeader :content_name="'创建新的权限组'" />

    <el-form ref="form" label-width="160px" :label-position="'left'">
      <el-form-item label="权限名">
        <el-input v-model="form.roles_name"></el-input>
      </el-form-item>

      <el-form-item label="权限组说明">
        <el-input type="textarea" v-model="form.roles_info"></el-input>
      </el-form-item>
    </el-form>

    <el-divider></el-divider>

    <el-table
      :data="tableData"
      border
      max-height="600"
    >
      <el-table-column prop="id" label="ID" width="180">
        <template slot-scope="scope">
          <div class="table-item">
            <div class="left-table-item">{{ scope.row.module_name }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="name" label="姓名">
        <template slot-scope="scope">
          <span
            class="checkBox-item"
            v-for="(value, item) in scope.row.resources"
            :key="item"
          >
            <el-checkbox v-model="value.status">{{ value.resources_name }}</el-checkbox>
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-divider></el-divider>

    <el-form ref="form" label-width="100px">
      <el-button type="primary" @click="postMsg()">确认无误，创建新权限组</el-button>
    </el-form>
  </div>
</template>

<script>
import { personnelAccount_JurisdictionGroupCreate, personnelAccount_JurisdictionGroupInitlist } from "@/api/manage/personnel_account_jurisdiction.js"
import { Message } from "element-ui";
export default {
  name: "start",
  data() {
    return {
      form: {
        roles_name: null,
        roles_info: null,
      },
      tableData: []
    };
  },
  components: {},
  watch: {},
  created() {
    this.$http(personnelAccount_JurisdictionGroupInitlist({}), (res) => {
      this.tableData = res.data;
      console.error(this.tableData)
    });
  },
  methods: {
    postMsg() {
      Message
      //校验
      if (this.form.roles_name == null || this.form.roles_info == null) {
        Message({
          message: "请输入字段",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      } else{
        //拼接数据
        var tempData=this.tableData
        var tempjurisdiction=[]
        tempData.forEach(element => {
            element.resources.forEach((elementItem)=>{
              if(elementItem.status==true){
                tempjurisdiction.push({"module_id":element.module_id,"resources_id":elementItem.resources_id})
              }
            })
        });
        this.form["jurisdiction"]=tempjurisdiction
        //拼接完成，执行逻辑
        this.$http(personnelAccount_JurisdictionGroupCreate(this.form), (res) => {
          Message({
            message: "新增成功",
            type: "success",
            duration: 5 * 1000,
          });
          console.log("新增成功",res)
        });
        this.$router.push({name:'personnelManagement_JurisdictionManage'})
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
